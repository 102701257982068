import 'slick-carousel'

import momemt from 'moment';



jQuery( document ).ready(function($) {



  // Functions

  const activeThisOnClick = (e) => {

    e.currentTarget.classList.toggle('active')

  }



  const removeActiveNavItems = () => {

    $('.nav a').removeClass('active')

  }



  const toggleMenu = () => {

    document.querySelector('header .main').classList.toggle('active')

  }



  const hideMenu = () => {

    document.querySelector('header .main').classList.remove('active')

  }



  const showSection = (e) => {

    const target = e.currentTarget.getAttribute('href')

    $('section').removeClass('active')

    $(`${target}`).addClass('active')

  }



  const toggleAllVideos = (e) => {

    e.preventDefault()

    $('#videos').toggleClass('all')

  }





  // Events

  $('.menu-trigger').click(function(e){

    activeThisOnClick(e)

    toggleMenu()

  })

  

  $('.header-logo').click(function(e){

    removeActiveNavItems()

    activeThisOnClick(e)

    showSection(e)

    toggleMenu()

  })



  $('.nav .toggle').click(function(e){

    removeActiveNavItems()

    activeThisOnClick(e)

    showSection(e)

    hideMenu()

    $('.menu-trigger').removeClass('active') 

  })



  $('.btn-all-videos').click(toggleAllVideos)





  // Sliders

  $('.slider-music').slick({

    slidesToShow: 1,

    slidesToScroll: 1,

    mobileFirst: true,

    prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-arrow-left"></button>',

    nextArrow: '<button type="button" class="slick-next"><i class="fas fa-arrow-right"></button>',

    responsive: [

      {

        breakpoint: 992,

        settings: 'unslick'

      }

    ]

  });



  // videos hover

  $(".video-wrapper.has-vid").hover(function () {

    $(this).children("video")[0].play();

  }, function () {

      var el = $(this).children("video")[0];

      el.pause();

      el.currentTime = 0;

  });



  $.ajax( {

    url: 'https://rest.bandsintown.com/artists/id_15513941/events?app_id=45PRESS_larosa',

    method: 'GET',

    dataType: 'json',

    error: () => {

      alert( 'Error fetching events!' );

    },

    success: data => {

    const events = $( '#events' );

    let html = '';

    if ( data.length ) {

      for ( let event of data ) {

        let location = event.venue.city + ', ' + event.venue.region;

        if ( location === ', ' ) {location = '';}

        if ( event.venue.name.toLowerCase() === 'streaming live' ) {location = 'Online';}

        html += '<div class="event">';

        html += '<div class="event-date">' + momemt( event.datetime ).format( 'MMM D' ) + '</div>';

        html += '<div class="event-info">';

        html += '<div class="event-location">' + location + '</div>';

        html += '<div class="event-venue">' + event.venue.name + '</div>';

        html += '</div>';

        html += '<div class="event-links">';

        for ( let offer of event.offers ) {

          html += '<a href="' + offer.url + '" target="_blank" class="btn btn-white-outline">' + offer.type + '</a>';

        }

        html += '</div>';

        html += '</div>';

      }

      events.html( html );

      } else {

        events.html( 'No upcoming events.' );

      }

    }

  });



  $(window).scroll(function() {    

    var scroll = $(window).scrollTop();

    if (scroll >= 60) {

      $("header .top").addClass("sticky");

    } else{

      $("header .top").removeClass("sticky");

    }

  }); //missing );



});